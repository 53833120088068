import styled from '@emotion/styled'
import React from 'react'
import Word from './font'
import { CartContext, useShopStoreIncontext } from '../../context/shopContext'

const Con1 =
  'https://cdn.july.com/personalisation/personalisation/per-svg/1-2.svg'
const Con2 =
  'https://cdn.july.com/personalisation/personalisation/per-svg/2.svg'
const Con3 =
  'https://cdn.july.com/personalisation/personalisation/per-svg/3.svg'
const Cross = 'https://cdn.july.com/personalisation/personalisation/back-x.svg'

const DisplayTextContainer = styled.div`
  display: ${(props) => (props.accepted ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
  text-size-adjust: none;
  ${(props) => (props.font !== 'Block' ? 'font-size: 110px;' : '')}
  background: transparent;
  line-height: 0;
  position: absolute;
  z-index: 999;
  top: 10%;
  right: 0;
  left: 0%;
  z-index: 1000;
  @media (max-width: 801px) {
    display: none;
  }
`

const CaseImageContainer = styled.div`
  position: absolute !important;
  font-size: 1.4em;
  @media (min-width: 800px) {
    top: ${(props) =>
    props.location === 'Top' && !props.isBottle ? '45%' : '25%'};
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 801px) {
    @media (min-height: 630px) {
      display: flex;
      justify-content: center;
    }
  }
`
const CaseImage = styled.img`
  width: 100%;
  height: 100%;
  min-width: 500px;
`

const OverlayContainer = styled.div`
  position: relative;
  width: 500px;
  mix-blend-mode: multiply;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
  text-size-adjust: none;
  @media (min-height: 630px) {
    width: 500px;
  }
`

const CaseTextContainerPositions = (location, caseSize, font) => {
  const values = {
    Top: {
      Carry: `
      top: 6%;
      left: 0;
      right: 0;
    `,
      Pro: `
      top: 13%;
      left: 0;
      right: 0;
    `,
      Light: `
      top: 11%;
      left: 0;
      right: 0;
    `,
      Checked: `
      top: 8%;
      left: 0;
      right: 0;
    `,
      Plus: `
      top: 11%;
      left: 0;
      right: 0;
    `,
    },
    Back: {
      Carry: `
      top: 78%;
      ${font === 'Block' ? 'top: 76%;' : ''}
      left: 0;
      right: 0;
    `,
      Light: `
        top: 78%;
        ${font === 'Block' ? 'top: 76%;' : ''}
        left: 0;
        right: 0;
      `,
      Pro: `
      top: 78%;
      ${font === 'Block' ? 'top: 76%;' : ''}
      left: 0;
      right: 0;
    `,
      Checked: `
      top: 68%;
      ${font === 'Block' ? 'top: 65%;' : ''}
      left: 0;
      right: 0;
    `,
      Plus: `
      top: 65%;
      ${font === 'Block' ? 'top: 62%;' : ''}
      left: 0;
      right: 0;
    `,
      Tote: `
      top: 69%;
      left: -2%;
      right: 0;
    `,
      Backpack: `
      top: 62%;
      left: 1.5%;
      right: 0;
    `,
      Daypack: `
      top: 58%;
      left: -1%;
      right: 0;
    `,
      Weekender: `
      top: 63%;
      left: 0%;
      right: 0;
    `,
      Me: `
      top: 62%;
      ${font === 'Block' ? 'top: 61%;' : ''}
      left: 25%;
      right: 0;
    `,
    },
    Side: {
      Carry: `
      transform: rotate(-90deg);
      bottom: 19%;
      left: -19.55%;
      right: 0%;
    `,
      Pro: `
      transform: rotate(-90deg);
      bottom: 19%;
      left: -19.55%;
      right: 0%;
    `,
      Light: `
        transform: rotate(-90deg);
        bottom: 24%;
        left: -19.55%;
        right: 0%;
      `,
      Checked: `
      transform: rotate(-90deg);
      bottom: 18%;
      left: -24.55%;
      right: 0%;
    `,
      Plus: `
      transform: rotate(-90deg);
      bottom: 18%;
      left: -23%;
      right: 0;
      ${font === 'Block' ? 'left: -22%;' : ''}
    `,
    },
  }
  return values[location][caseSize]
}

const LocationData = (positions, size) => {
  return `
    display: flex;
    justify-content: center;
    align-items: center;
    ${positions.top && `top: ${positions.top};`}
    ${positions.right && `right: ${positions.right};`}
    ${positions.bottom && `bottom: ${positions.bottom};`}
    ${positions.left && `left: ${positions.left};`}

    ${positions.transform && `transform: ${positions.transform};`}

    width: ${size.width};
    height: ${size.height};
  `
}

const CaseTextContainer = styled.div`
  position: absolute;
  text-align: center;
  ${(props) =>
    !props.locationData && props.font !== 'Block' ? 'padding-top: 16px;' : ''}
  ${(props) => {
    if (props.locationData) {
      return LocationData(props.locationData, props.size)
    } else {
      return CaseTextContainerPositions(
        props.location,
        props.caseSize,
        props.font
      )
    }
  }}
`

const PreviewPanel = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background-color: #f5f3f3;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const HeaderTextMobile = styled.div`
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: -0.61px;
  color: #000000;
  margin: 16px 24px;
  padding: 12px 0;
  @media (min-width: 801px) {
    display: none;
  }
`
const ConfirmCopy = styled.div`
  height: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  text-align: center;
  color: #282828;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const DesktopConfirmHeading = styled.div`
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: -1.11px;
  text-align: center;
  color: #000000;
  margin-bottom: 32px;
  @media (max-width: 800px) {
    display: none;
  }
`

const MobileConfirmHeading = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: -0.7px;
  text-align: center;
  color: #000000;
  margin-bottom: 16px;
  @media (min-width: 801px) {
    display: none;
  }
`

const ConfirmSubHeading = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.22px;
  text-align: center;
  color: #282828;
  padding: 0 8px;
  @media (max-width: 800px) {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.15px;
    text-align: center;
    color: #242424;
  }
`

const InfomationConfirmSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 32px;
  @media (max-width: 800px) {
    margin-top: 16px;
    margin-bottom: 12px;
    flex-direction: column;
  }
`

const InfoPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 16px;
  width: 220px;
  @media (max-width: 800px) {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
`

const InfoPanelImage = styled.img`
  height: 130px;
  @media (max-width: 800px) {
    height: auto;
    width: 100px;
  }
`

const InfoPanelCopy = styled.div`
  text-align: center;
  @media (max-width: 800px) {
    text-align: left;
    flex-grow: 1;
    margin-top: 16px;
    margin-left: 12px;
  }
`

const InfomationPanel = ({ image, copy }) => {
  return (
    <InfoPanelContainer>
      <InfoPanelImage src={image} />
      <InfoPanelCopy>{copy}</InfoPanelCopy>
    </InfoPanelContainer>
  )
}

const Preview = ({
  handleExit,
  accepted,
  caseSize,
  text,
  font,
  color,
  shadow,
  location,
  fontSize,
  textColors,
  textTransform,
  selectedCaseColor,
  title,
  locationData,
  maxLetters,
  isMe,
  isBottle,
  isBottle750,
  isPhone,
  selectedStep,
  imageName,
  isBag,
  timeframe
}) => {
  const { storeLocale } = useShopStoreIncontext((state) => {
    return {
      storeLocale: state.storeLocale,
    }
  })

  const ConfirmOverlay = (
    <ConfirmCopy style={{ display: accepted === false ? 'flex' : 'none' }}>
      <div>
        <DesktopConfirmHeading>
          {' '}
          {storeLocale === 'en' ? 'Personalizing' : 'Personalising'} your item is a <br /> great way to make it yours.{' '}
        </DesktopConfirmHeading>
        <MobileConfirmHeading> Make it yours. </MobileConfirmHeading>
        <ConfirmSubHeading>
          {' '}
          A couple things to note before you get started:{' '}
        </ConfirmSubHeading>
        <InfomationConfirmSection>
          <InfomationPanel
            image={Con1}
            copy={
              <>
                {storeLocale === 'en' ? 'Personalization' : 'Personalisation'} adds{' '}
                <b>
                  {timeframe}
                  <br />
                  business days
                </b>{' '}
                to shipping time. <br />
              </>
            }
          />
          <InfomationPanel
            image={Con2}
            copy={
              <>
                There is no 100 day returns <br />
                for {storeLocale === 'en' ? 'personalized' : 'personalised'} items. <br />
              </>
            }
          />
          <InfomationPanel
            image={Con3}
            copy={
              <>
                Warranty still applies to all <br />
                luggage, bags, and accessories.
              </>
            }
          />
        </InfomationConfirmSection>
        {/* {['en-AU', 'en-NZ'].includes(storeLocale) && (
          <ConfirmSubHeading>
            Orders with personalisation might not make it in time for Christmas.
          </ConfirmSubHeading>
        )} */}
      </div>
    </ConfirmCopy>
  )
  return (
    <>
      <PreviewPanel>
        <span
          role="button"
          tabIndex={0}
          onKeyDown={handleExit}
          onClick={handleExit}
          style={{
            fontWeight: 600,
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '16px 16px',
            cursor: 'pointer',
            color: '#000000',
            zIndex: 999,
          }}
        >
          {' '}
          <img alt="Exit Cross image" style={{ width: 48, height: 48 }} src={Cross} />{' '}
        </span>
        <HeaderTextMobile> {title} </HeaderTextMobile>
        {accepted === false && ConfirmOverlay}
        <div
          style={{
            display: accepted === true ? 'flex' : 'none',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            marginTop: 64,
          }}
        >
          <CaseImageContainer
            moveUp={isPhone === true && selectedStep === 0}
            isBottle={isBottle}
            location={location}
            mobile={true}
          >
            <OverlayContainer>
              <CaseImage
                mobile={true}
                src={`https://cdn.july.com/personalisation/cases/${imageName && imageName.toLowerCase()
                  }/${location && location.toLowerCase()}/${selectedCaseColor + 1 < 10 ? '0' : ''
                  }${selectedCaseColor + 1}.webp`}
              />

              <CaseTextContainer
                size={locationData.displaySize}
                locationData={locationData}
                font={font}
                caseSize={caseSize}
                location={location}
              >
                <Word
                  refreshToken={font + location}
                  left={locationData.leftAlign}
                  passRefesh={accepted}
                  maxLetters={maxLetters}
                  size={locationData.displaySize}
                  mainCol={color}
                  backCol={shadow}
                  letters={text}
                  fontSize={fontSize}
                  font={font.toLowerCase()}
                />
              </CaseTextContainer>
            </OverlayContainer>
          </CaseImageContainer>
        </div>
        <DisplayTextContainer
          accepted={accepted === true}
          font={font}
          pos={location}
        >
          <Word
            refreshToken={font + location}
            passRefesh={accepted}
            maxLetters={maxLetters}
            size={locationData.previewSize}
            mainCol={color}
            backCol={shadow}
            letters={text}
            fontSize={fontSize}
            font={font.toLowerCase()}
          />
        </DisplayTextContainer>
        <div style={{ opacity: 0 }}>
          <Word
            refreshToken={font + location}
            passRefesh={accepted}
            maxLetters={maxLetters}
            size={locationData.previewSize}
            mainCol={color}
            backCol={shadow}
            letters={text}
            fontSize={32}
            font={font.toLowerCase()}
          />
        </div>
      </PreviewPanel>
    </>
  )
}

export default Preview
